import React from "react";
import "./unstopbtn.css";

const Unstopbtn = () => {
	return (
		<div className="container">
			<div className="btn">
				<a href="https://unstop.com/hackathons/spit-hackathon-2024-bharatiya-vidya-bhavans-sardar-patel-institue-of-technology-mumbai-835961">
					View On UnStop
				</a>
			</div>
		</div>
	);
};

export default Unstopbtn;
