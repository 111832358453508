import React from 'react'
import './Sponsor.css'
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";

const Sponsor = () => {
  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: 'ease-in-out',
    });
  }, []);
  return (
    <div className='outersponsor'>
      <h3 id='heading' data-a0s="zoom">Sponsors</h3>

      <div className='sponsorsbox'>
        <div className='sponsor' data-aos="fade-up">
          <h2>Title Sponsor</h2>
          <div className='logos'>
            <img src={require('../../imgs/benq.png')} id='img2' ></img>
          </div>
        </div>
        <div className='sponsor' data-aos="fade-up">
          <h2>Associate Sponsor</h2>
          <div className='logos'>
            <img src='https://upload.wikimedia.org/wikipedia/en/a/a1/Figaro%27s_logo.png' id='img1' ></img>
            <img src='https://www.imperialonlinestudy.com/static/media/logo.857ab95d.png' id='img6'></img>
          </div>
          
        </div>
        <div className='sponsor' data-aos="fade-up">
          <h2>Gaming Partner</h2>
          <div className='logos'>
            <img src='https://static.wixstatic.com/media/75adb1_6a8580aa703a44599b7ea3d8b9444b98~mv2.png/v1/crop/x_0,y_373,w_1080,h_338/fill/w_560,h_176,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/simplygaming_edited.png' id='img3' ></img>
          </div>
          
        </div>
        <div className='sponsor' data-aos="fade-up">
          <h2>Cybersecurity Partner</h2>
          <div className='logos'>
            <img src={require('../../imgs/hacktify.png')} id='img4' ></img>
          </div>
          
        </div>
        <div className='sponsor' data-aos="fade-up">
          <h2>Non-alcoholic Drink Partner</h2>
          <div className='logos'>
            <img src='https://images.yourstory.com/cs/images/companies/Coolberg-1594800710996.png?fm=auto&ar=1:1&mode=fill&fill=solid&fill-color=fff' id='img5' ></img>
          </div>
          
        </div>
        

      </div>


    </div>
  )
}

export default Sponsor
